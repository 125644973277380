export const toolDisplayMapping = {
  'car_stock_search': 'Sto verificando nel nostro stock',
  'knowledge_search_engine': 'Sto cercando le informazioni',
  'booking_': 'Sto controllando la nostra agenda',
  'book_': 'Sto controllando la nostra agenda',
  'location_info_retriever':'Ancora qualche secondo...',
  'customer_registration': 'Sto controllando i nostri registri',
  'product_search': 'Ricerca prodotto',
  'order_tracking': 'Tracciamento ordine',
  'customer_support': 'Supporto clienti',
  'recommendation_engine': 'Suggerimento prodotti',
  'price_checker': 'Controllo prezzi',
  'inventory_check': 'Verifica disponibilità',
  'payment_processing': 'Elaborazione pagamento',
  'return_request': 'Richiesta di reso',
  'shipping_calculator': 'Calcolo spese di spedizione',
  'wishlist_manager': 'Gestione lista desideri',
  'coupon_validator': 'Verifica coupon',
  'size_guide': 'Guida alle taglie',
  'product_comparison': 'Confronto prodotti',
  'gift_wrapping': 'Confezione regalo',
  'loyalty_program': 'Programma fedeltà',
  'newsletter_subscription': 'Iscrizione newsletter',
  'product_review': 'Recensione prodotto',
  'store_locator': 'Trova negozio',
  'virtual_fitting_room': 'Camerino virtual',
  'emergency': 'Sto contattando un nostro operatore'
};

export const getToolDisplay = (tool) => {
  // First try exact match
  if (toolDisplayMapping[tool]) {
    return toolDisplayMapping[tool];
  }

  // If no exact match, try prefix match
  const matchingKey = Object.keys(toolDisplayMapping).find(key => 
    tool.startsWith(key)
  );

  return matchingKey ? toolDisplayMapping[matchingKey] : tool;
};