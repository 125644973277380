import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Typography,
  IconButton,
  Tab,
  Tabs,
  CardMedia,
  useTheme,
  ThemeProvider,
  createTheme,
  styled,
  Skeleton,
  Alert,
  CircularProgress
} from '@mui/material';

import {
  Close as CloseIcon,
  Info,
  Directions,
  ExpandLess,
  ExpandMore,
  DirectionsCar,
  ArrowUpward,
  Star,
  Speed,
  LocalGasStation,
  EventSeat,
  ColorLens,
} from '@mui/icons-material';

import CustomScrollbar from '../../CustomScrollbar';
import ImageGallery from './ImageGallery';
import Accessories from './Accessories';
import { StyledComponentsThemWrapper } from '../../StyledComponentsThemWrapper';

// Enhanced theme with modern styling
const customTheme = createTheme({
  typography: {
    fontFamily: "'Plus Jakarta Sans', 'Inter', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: "linear-gradient(135deg, #2563eb 0%, #3b82f6 100%)",
          color: "#FFFFFF",
          transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 8px 20px rgba(37, 99, 235, 0.25)",
          }
        },
      },
    },
  },
});

const formatPrice = (price) => {
  return parseFloat(price).toLocaleString("it-IT", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

const defaultImage = "https://www.aitomotivelab.com/images/logo/AitomotiveLab_White.svg";

// Enhanced styled components with glass morphism effects
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  padding: theme.spacing(2),
  position: "relative",
  backdropFilter: "blur(10px)",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%)",
    opacity: 0.3,
  }
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "0.925rem",
  textTransform: "none",
  letterSpacing: "0.025em",
  transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
  color: theme.palette.v2.textSecondary,
  "&.Mui-selected": {
    color: theme.palette.v2.primary,
    fontWeight: 700,
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "3px",
      background: theme.palette.v2.primary,
      borderRadius: "3px 3px 0 0",
    }
  },
  "&:hover": {
    backgroundColor: "rgba(37, 99, 235, 0.04)",
    transform: "translateY(-1px)",
  }
}));

const InfoCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: "16px",
  backgroundColor: "#ffffff",
  position: "relative",
  overflow: "hidden",
  border: "1px solid rgba(0,0,0,0.05)",
  boxShadow: "0 4px 6px rgba(0,0,0,0.02), 0 1px 3px rgba(0,0,0,0.03)",
  transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "4px",
    background: theme.palette.v2.primary,
    opacity: 0,
    transition: "opacity 0.3s ease",
  },
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 12px 24px rgba(0,0,0,0.05), 0 4px 8px rgba(0,0,0,0.04)",
    "&::before": {
      opacity: 1,
    }
  }
}));

// Enhanced TabPanel with improved animations
const TabPanel = ({ children, value, index }) => (
  <motion.div
    role="tabpanel"
    initial={{ opacity: 0, x: 20 }}
    animate={{ 
      opacity: value === index ? 1 : 0,
      x: value === index ? 0 : 20,
      display: value === index ? 'flex' : 'none',
    }}
    transition={{ 
      duration: 0.4,
      ease: [0.4, 0, 0.2, 1]
    }}
    style={{ 
      height: '100%',
      position: 'absolute',
      width: '100%',
      top: 0,
      left: 0,
      overflow: 'hidden'
    }}
  >
    {value === index && children}
  </motion.div>
);

// Enhanced GeneralInfo with dynamic icons and animations
const GeneralInfo = ({ vehicleData }) => {
  const theme = useTheme();

  const infoItems = [
    {
      label: "Anno di Immatricolazione",
      value: vehicleData.registrationYear,
      icon: <EventSeat sx={{ color: theme.palette.v2.primary }} />,
      check: true,
    },
    {
      label: "Colore",
      value: vehicleData.colour,
      icon: <ColorLens sx={{ color: theme.palette.v2.primary }} />
    },
    {
      label: "Chilometri",
      value: formatPrice(vehicleData.km),
      icon: <Speed sx={{ color: theme.palette.v2.primary }} />,
      check: true,
    },
    {
      label: "Prezzo di Listino",
      value: vehicleData.retailPrice,
      icon: <Star sx={{ color: theme.palette.v2.primary }} />,
      check: true,
    },
    {
      label: "Prezzo di Vendita",
      value: vehicleData.salePrice,
      icon: <Star sx={{ color: theme.palette.v2.primary }} />,
      check: true,
      highlight: true,
    },
    {
      label: "Gruppo Modello",
      value: vehicleData.modelGroup,
      icon: <DirectionsCar sx={{ color: theme.palette.v2.primary }} />,
    },
    {
      label: "Carburante",
      value: vehicleData.fuel,
      icon: <LocalGasStation sx={{ color: theme.palette.v2.primary }} />
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Grid container spacing={3}>
        {infoItems
          .filter(item => item.check ? parseFloat(item.value) > 0 : item.value?.toString().trim() !== "")
          .map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ 
                  delay: index * 0.1,
                  duration: 0.4,
                  ease: [0.4, 0, 0.2, 1]
                }}
              >
                <InfoCard
                  sx={{
                    background: item.highlight ? 
                      'linear-gradient(135deg, rgba(37,99,235,0.05) 0%, rgba(59,130,246,0.05) 100%)' : 
                      'white'
                  }}
                >
                  <Box sx={{ 
                    display: "flex", 
                    alignItems: "center", 
                    mb: 1,
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: -8,
                      left: 0,
                      right: 0,
                      height: "1px",
                      background: "linear-gradient(90deg, rgba(37,99,235,0.1) 0%, rgba(59,130,246,0.1) 100%)",
                    }
                  }}>
                    {item.icon}
                    <Typography
                      variant="subtitle2"
                      sx={{ 
                        ml: 1, 
                        color: 'text.secondary',
                        fontWeight: 600,
                        letterSpacing: "0.01em"
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                  <Typography 
                    variant="body1"
                    sx={{
                      fontWeight: 700,
                      color: item.highlight ? theme.palette.v2.primary : 'text.primary',
                      fontSize: item.highlight ? "1.25rem" : "inherit",
                      mt: 1
                    }}
                  >
                    {item.label.includes("Prezzo") ? (
                      <>
                        {item.label === "Prezzo di Listino" ? (
                          <Typography
                            variant="body2"
                            sx={{ 
                              color: 'text.disabled',
                              textDecoration: 'line-through',
                              fontWeight: 500
                            }}
                          >
                            {`${formatPrice(item.value)} €`}
                          </Typography>
                        ) : (
                          <motion.div
                            initial={{ scale: 0.95 }}
                            animate={{ scale: 1 }}
                            transition={{ 
                              duration: 0.4,
                              ease: [0.4, 0, 0.2, 1]
                            }}
                          >
                            {`${formatPrice(item.value)} €`}
                          </motion.div>
                        )}
                      </>
                    ) : (
                      item.value
                    )}
                  </Typography>
                </InfoCard>
              </motion.div>
            </Grid>
          ))}
      </Grid>
    </motion.div>
  );
};

// Enhanced loading skeleton with smooth animations
const LoadingSkeleton = () => (
  <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
      style={{
        height: '55%',
        background: '#f1f5f9',
        padding: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box 
        sx={{ 
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '16px',
          backdropFilter: 'blur(8px)'
        }}
      >
        <CircularProgress 
          sx={{ 
            color: '#2563eb',
            '& .MuiCircularProgress-circle': {
              strokeLinecap: 'round',
            }
          }} 
        />
      </Box>
    </motion.div>

    <Box
      sx={{
        height: '45%',
        background: '#ffffff',
        borderTopLeftRadius: '24px',
        borderTopRightRadius: '24px',
        boxShadow: '0 -4px 20px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden'
      }}
    >
      <Tabs
        value={0}
        centered
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          minHeight: { xs: '48px', md: '48px' },
          bgcolor: 'background.paper'
        }}
      >
        <StyledTab label="Informazioni Generali" />
        <StyledTab label="Accessori" />
      </Tabs>

      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  delay: index * 0.1,
                  duration: 0.4,
                  ease: [0.4, 0, 0.2, 1]
                }}
              >
                <InfoCard>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Skeleton variant="circular" width={24} height={24} />
                    <Skeleton variant="text" sx={{ ml: 1, width: '60%' }} />
                  </Box>
                  <Skeleton variant="text" sx={{ width: '80%' }} />
                </InfoCard>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  </Box>
);

// Main VehicleDialog component
const VehicleDialog = ({ open, handleClose, vehicleData, vehicleid }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      // Simulate loading delay
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [open]);

  useEffect(() => {
    if (vehicleData?.urlMainImage) {
      const baseUrl = vehicleData.urlMainImage.replace(/\/\d+\/large$/, "");
      setImages([0, 1, 2, 3, 4, 5].map((index) => `${baseUrl}/${index}/large`));
    } else {
      setImages([defaultImage]);
    }
  }, [vehicleData]);

  // Main VehicleDialog component (continued)
  if (vehicleData === null && !isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: { xs: 0, md: '16px' },
              m: { xs: 0, md: '2vh' },
              overflow: 'hidden'
            },
            '& .MuiBackdrop-root': {
              backdropFilter: 'blur(8px)',
              backgroundColor: 'rgba(0, 0, 0, 0.6)'
            }
          }}
        >
          <StyledDialogTitle>
            <Typography variant="h6" sx={{ fontWeight: 700, color: theme.palette.v2.textPrimary }}>
              Dettagli del Veicolo
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.1)',
                  transform: 'rotate(90deg)',
                  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <DialogContent 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              p: { xs: 2, md: 4 }, 
              minHeight: '300px'
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, ease: [0.4, 0, 0.2, 1] }}
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Alert 
                severity="info"
                variant="filled"
                sx={{
                  width: '100%',
                  height: '100%',
                  minHeight: '200px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  backdropFilter: 'blur(8px)',
                  background: theme.palette.v2.primary,
                  borderRadius: '16px',
                  boxShadow: '0 8px 32px rgba(37, 99, 235, 0.15)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  p: 4,
                  '& .MuiAlert-icon': {
                    fontSize: '3rem',
                    marginBottom: 2,
                    marginRight: 0
                  },
                  '& .MuiAlert-message': {
                    width: '100%'
                  }
                }}
              >
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontWeight: 700,
                    mb: 2,
                    fontSize: { xs: '1.25rem', md: '1.5rem' },
                    color: theme.palette.v2.textPrimary
                  }}
                >
                  Auto non più disponibile
                </Typography>
                <Typography 
                  variant="body1"
                  sx={{
                    fontSize: { xs: '1rem', md: '1.125rem' },
                    opacity: 0.9,
                    color: theme.palette.v2.textPrimary
                  }}
                >
                  Ci dispiace, ma questo veicolo non è più disponibile nel nostro catalogo.
                </Typography>
              </Alert>
            </motion.div>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        sx={{
          '& .MuiDialog-paper': {
            width: { xs: '100vw', md: 'min(80vw, 1200px)' },
            height: { xs: '100vh', md: 'min(85vh, 800px)' },
            minWidth: { md: '800px' },
            minHeight: { md: '600px' },
            maxWidth: { md: '1200px' },
            maxHeight: { md: '800px' },
            m: { xs: 0, md: '2vh' },
            overflow: 'hidden',
            borderRadius: { xs: 0, md: '24px' },
            bgcolor: '#f8fafc',
            backdropFilter: 'blur(16px)',
            boxShadow: '0 24px 48px rgba(0, 0, 0, 0.1)',
          },
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(8px)',
            backgroundColor: 'rgba(0, 0, 0, 0.6)'
          }
        }}
      >
        <StyledDialogTitle>
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.4, ease: [0.4, 0, 0.2, 1] }}
          >
            <Typography variant="h6" sx={{ fontWeight: 700, color: theme.palette.v2.textPrimary }}>
              Dettagli del Veicolo
            </Typography>
          </motion.div>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                transform: 'rotate(90deg)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>

        <DialogContent sx={{ p: 0 }}>
          {isLoading ? (
            <LoadingSkeleton />
          ) : (
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative', overflow: 'hidden' }}>
              <motion.div
                initial={false}
                animate={{
                  height: isExpanded ? "0%" : "55%",
                  opacity: isExpanded ? 0 : 1,
                  display: isExpanded ? "none" : "flex"
                }}
                transition={{ duration: 0.4, ease: [0.4, 0, 0.2, 1] }}
                style={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#f1f5f9',
                  position: 'relative'
                }}
              >
                <ImageGallery
                  images={images}
                  onError={(e) => { e.target.src = defaultImage }}
                  retailPrice={vehicleData.retailPrice}
                  salePrice={vehicleData.salePrice}
                  onFullScreen={setFullScreenImage}
                />
              </motion.div>

              <motion.div
                initial={false}
                animate={{
                  height: isExpanded ? "100%" : "45%",
                  y: 0
                }}
                transition={{ duration: 0.4, ease: [0.4, 0, 0.2, 1] }}
                style={{
                  position: 'relative',
                  backgroundColor: '#ffffff',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px',
                  boxShadow: '0 -4px 20px rgba(0, 0, 0, 0.1)',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden'
                }}
              >
                <Box
                  onClick={() => setIsExpanded(!isExpanded)}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 1,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                    '&:hover': {
                      bgcolor: 'rgba(37, 99, 235, 0.04)'
                    }
                  }}
                >
                  <motion.div
                    animate={{ rotate: isExpanded ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {isExpanded ? <ExpandMore /> : <ExpandLess />}
                  </motion.div>
                </Box>

                <Tabs
                  value={tabIndex}
                  onChange={(_, newValue) => setTabIndex(newValue)}
                  centered
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    minHeight: { xs: '48px', md: '48px' },
                    bgcolor: 'background.paper'
                  }}
                >
                  <StyledTab label="Informazioni Generali" />
                  <StyledTab label="Accessori" />
                </Tabs>

                <Box sx={{ flexGrow: 1, position: 'relative', overflow: 'hidden' }}>
                  <TabPanel value={tabIndex} index={0}>
                    <CustomScrollbar>
                      <Box sx={{ p: 3 }}>
                        <GeneralInfo vehicleData={vehicleData} />
                      </Box>
                    </CustomScrollbar>
                  </TabPanel>
                  <TabPanel value={tabIndex} index={1}>
                    <CustomScrollbar>
                      <Box sx={{ p: 3 }}>
                        <Accessories
                          standardAccessories={vehicleData.standardAccessories}
                          optionalAccessories={vehicleData.optionalAccessories}
                        />
                      </Box>
                    </CustomScrollbar>
                  </TabPanel>
                </Box>
              </motion.div>
            </Box>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={Boolean(fullScreenImage)}
        onClose={() => setFullScreenImage(null)}
        fullScreen
        sx={{
          '& .MuiDialog-paper': {
            bgcolor: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(16px)',
            backgroundColor: 'rgba(0, 0, 0, 0.9)'
          }
        }}
      >
        <IconButton
          onClick={() => setFullScreenImage(null)}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: 'white',
            zIndex: 1,
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              transform: 'rotate(90deg)'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        {fullScreenImage && (
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.4, ease: [0.4, 0, 0.2, 1] }}
            style={{ 
              width: '100%', 
              height: '100%', 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              padding: '32px'
            }}
          >
            <CardMedia
              component="img"
              image={fullScreenImage}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                borderRadius: '16px',
                boxShadow: '0 24px 48px rgba(0, 0, 0, 0.2)'
              }}
            />
          </motion.div>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export default VehicleDialog;