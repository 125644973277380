import {
  SmartToy as AIIcon,
  Close as CloseIcon,
  Person as PersonIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import {
  FaCompressArrowsAlt,
  FaExpandArrowsAlt,
  FaHeart,
} from "react-icons/fa";
import styled from "styled-components";
import { lightenColor } from "../utils/colors";
import { StyledComponentsThemWrapper } from "./StyledComponentsThemWrapper";
import WishlistPopup from "./WishlistPopup";

const HeaderContainer = styled(motion.div)`
  border-bottom: 1px solid ${(props) => props.theme.palette.borderColor};
  width: 100%;
  z-index: 1000;
`;

const StyledAppBar = styled.div`
  color: ${(props) => props.theme.palette.v2.textPrimary};
  background-color: ${(props) => props.theme.palette.v2.primary};
`;

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing(1, 2)};
`;

const AnimatedAvatar = styled(motion.create(Avatar))`
  width: 40px;
  height: 40px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const StatusIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.isOnline ? "#4CAF50" : "#FFA000")};
  position: absolute;
  bottom: 0;
  right: 0;
  border: 2px solid white;
`;

const StyledIconButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.v2.textPrimary};
`;

const ChatHeader = ({
  isMobile,
  toggleChat,
  toggleChatWidth,
  isChatWide,
  wishlistItems,
  onWishlistUpdate,
  onSendMessage,
  isLoading,
  isHumanControlled,
  humanControlMessage,
  isOnline,
}) => {
  const [isWishlistOpen, setIsWishlistOpen] = useState(false);
  const [typingEffect, setTypingEffect] = useState("");
  const [showControlMessage, setShowControlMessage] = useState(false);
  const theme = useTheme();

  const handleWishlistToggle = () => setIsWishlistOpen(!isWishlistOpen);

  useEffect(() => {
    setShowControlMessage(true);
    let message = isHumanControlled ? (
      humanControlMessage || "Un operatore è ora disponibile."
    ) : (
      <span>
        <img
          src="https://aitomotivelab.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FLOGO_MIA.731a2d9a.png&w=1200&q=75"
          alt="MIA Logo"
          width="34"
          height="12"
          style={{ marginRight: "4px", verticalAlign: "middle" }}
        />
        può commettere errori, verifica le informazioni.
      </span>
    );

    if (typeof message === "string") {
      let i = 0;
      const typing = setInterval(() => {
        setTypingEffect(message.slice(0, i));
        i++;
        if (i > message.length) {
          clearInterval(typing);
        }
      }, 50);

      return () => clearInterval(typing);
    } else {
      // If message is a JSX element, we don't animate it
      setTypingEffect(message);
    }
  }, [isHumanControlled, humanControlMessage]);

  return (
    <StyledComponentsThemWrapper>
      <HeaderContainer
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <StyledAppBar position="static" elevation={3}>
          <StyledToolbar>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AnimatedAvatar
                src={localStorage.getItem("logo")}
                alt="Chat Logo"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <StatusIndicator isOnline={isOnline} />
              </AnimatedAvatar>
              <Box>
                <Typography variant="h6" color={theme.palette.v2.textPrimary}>
                  {localStorage.getItem("name_chat")}
                </Typography>
                <Typography variant="caption">
                  {isHumanControlled ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PersonIcon fontSize="small" sx={{ marginRight: 0.5 }} />
                      Operatore online
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <AIIcon fontSize="small" sx={{ marginRight: 0.5 }} />
                      Assistente AI
                    </Box>
                  )}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AnimatePresence>
                {wishlistItems.length > 0 && (
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                  >
                    <Badge
                      badgeContent={wishlistItems.length}
                      color="secondary"
                    >
                      <StyledIconButton
                        aria-label="wishlist"
                        onClick={handleWishlistToggle}
                        disabled={isLoading}
                      >
                        <FaHeart style={{ color: theme.palette.v2.textPrimary }} />
                      </StyledIconButton>
                    </Badge>
                  </motion.div>
                )}
              </AnimatePresence>
              {!isMobile && (
                <StyledIconButton onClick={toggleChatWidth}>
                  {isChatWide ? 
                    <FaCompressArrowsAlt style={{ color: theme.palette.v2.textPrimary }} /> : 
                    <FaExpandArrowsAlt style={{ color: theme.palette.v2.textPrimary }} />
                  }
                </StyledIconButton>
              )}
              <StyledIconButton onClick={toggleChat}>
                <CloseIcon sx={{ color: theme.palette.v2.textPrimary }} />
              </StyledIconButton>
            </Box>
          </StyledToolbar>
        </StyledAppBar>
        <AnimatePresence>
          {showControlMessage && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Box
                sx={{
                  padding: theme.spacing(1, 2),
                  backgroundColor: isHumanControlled
                    ? "rgba(76, 175, 80, 0.1)"
                    : lightenColor(theme.palette.primary.main, 28),
                  textAlign: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                  {typingEffect}
                </Typography>
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </HeaderContainer>
      <WishlistPopup
        open={isWishlistOpen}
        handleClose={() => setIsWishlistOpen(false)}
        wishlistItems={wishlistItems}
        onWishlistUpdate={onWishlistUpdate}
        onSendMessage={onSendMessage}
      />
    </StyledComponentsThemWrapper>
  );
};

export default ChatHeader;
