import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Campaign as CampaignIcon,
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Help as HelpIcon,
  Lock as LockIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { fetchRegistrationForm, submitFormResponse } from "../../api/api";

const BASE_URL = "https://stage-admin.aitomotivelab.com/formbuilder";

const PersonalDataForm = ({ onSubmit, onSendMessage, setIsLoading }) => {
  const [formData, setFormData] = useState(null);
  const [formState, setFormState] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isEditing, setIsEditing] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [originalFormState, setOriginalFormState] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [expandedFields, setExpandedFields] = useState({});
  const [hasChanges, setHasChanges] = useState(false);

  const theme = useTheme();
  const chatBodyRef = useRef(null);

  const scrollToBottom = () => {
    // Update the selector to be more specific and reliable
    const chatBody = document.querySelector('.messages-container');
    if (chatBody) {
      chatBody.scrollTop = chatBody.scrollHeight;
    }
  };


  useEffect(() => {
    fetchFormData();
    const savedFormState = localStorage.getItem("formState");
    const savedIsSubmitted = localStorage.getItem("isSubmitted");

    if (savedFormState) {
      const parsedState = JSON.parse(savedFormState);
      setFormState(parsedState);
      setOriginalFormState(parsedState);
    }
    if (savedIsSubmitted) {
      setIsSubmitted(JSON.parse(savedIsSubmitted));
      setIsEditing(false);
    }
  }, []);

  const fetchFormData = async () => {
    try {
      const response = await fetchRegistrationForm();
      setFormData(response.data);
      initializeFormState(response.data);
    } catch (error) {
      setError("Failed to fetch form data. Please try again later.");
    }
  };

  const initializeFormState = (data) => {
    const savedFormState = localStorage.getItem("formState");
    if (savedFormState) {
      const parsedState = JSON.parse(savedFormState);
      setFormState(parsedState);
      setIsSubmitted(!!parsedState.id);
      setIsEditing(!parsedState.id);
    } else {
      const initialState = {};
      data.pages.forEach((page) => {
        page.groups.forEach((group) => {
          group.fields.forEach((field) => {
            if (field.field_type === "checkbox") {
              initialState[field.field_id] = [];
            } else {
              initialState[field.field_id] = "";
            }
          });
        });
      });
      setFormState(initialState);
      localStorage.setItem("formState", JSON.stringify(initialState));
    }
  };

  const handleChange = (fieldId, value, fieldType) => {
    if (fieldType === "checkbox") {
      setFormState((prevState) => {
        const currentValues = prevState[fieldId] || [];
        const newValues = currentValues.includes(value)
          ? currentValues.filter((v) => v !== value)
          : [...currentValues, value];
        const newState = { ...prevState, [fieldId]: newValues };
        setHasChanges(
          JSON.stringify(newState) !== JSON.stringify(originalFormState)
        );
        localStorage.setItem("formState", JSON.stringify(newState));
        return newState;
      });
    } else {
      setFormState((prevState) => {
        const newState = { ...prevState, [fieldId]: value };
        setHasChanges(
          JSON.stringify(newState) !== JSON.stringify(originalFormState)
        );
        localStorage.setItem("formState", JSON.stringify(newState));
        return newState;
      });
    }
  };

  const evaluateCondition = useCallback(
    (conditionsArray) => {
      const evaluateSingleCondition = (condition) => {
        const { fieldId, type, value } = condition;
        const fieldValue = formState[fieldId];

        if (Array.isArray(fieldValue)) {
          // Checkbox field handling
          switch (type) {
            case "equals":
              return (
                JSON.stringify(fieldValue.sort()) ===
                JSON.stringify(
                  value
                    .split(",")
                    .map((v) => v.trim())
                    .sort()
                )
              );
            case "includes":
              return fieldValue.includes(value);
            case "not_includes":
              return !fieldValue.includes(value);
            default:
              return false;
          }
        } else {
          // Non-array fields (standard fields)
          switch (type) {
            case "equals":
              return fieldValue === value;
            case "not_equals":
              return fieldValue !== value;
            case "contains":
              return String(fieldValue).includes(value);
            case "not_contains":
              return !String(fieldValue).includes(value);
            case "greater_than":
              return Number(fieldValue) > Number(value);
            case "less_than":
              return Number(fieldValue) < Number(value);
            default:
              return true;
          }
        }
      };

      let result = true;
      let currentOperator = "AND";

      for (let i = 0; i < conditionsArray.length; i++) {
        const condition = conditionsArray[i];
        const conditionResult = evaluateSingleCondition(condition);

        if (currentOperator === "AND") {
          result = result && conditionResult;
        } else {
          result = result || conditionResult;
        }

        currentOperator = condition.logicalOperator || "AND";

        if (currentOperator === "AND" && !result) break;
        if (currentOperator === "OR" && result) break;
      }

      return result;
    },
    [formState]
  );

  const evaluateFieldConditions = useCallback(
    (conditions) => {
      if (!conditions || conditions === "[]") return true;
      try {
        const parsedConditions = JSON.parse(conditions);
        return evaluateCondition(parsedConditions);
      } catch (error) {
        console.error("Error parsing field conditions:", error);
        return true;
      }
    },
    [evaluateCondition]
  );

  const visiblePages = useMemo(() => {
    if (formData && Array.isArray(formData.pages)) {
      return formData.pages.filter((page) =>
        evaluateFieldConditions(page.conditions)
      );
    }
    return [];
  }, [formData, evaluateFieldConditions]);

  useEffect(() => {
    calculateProgress();
  }, [formState, visiblePages]);

  const renderNavigation = () => (
    <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
      {!isSubmitted && (
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => setActiveStep((prevStep) => Math.max(0, prevStep - 1))}
          disabled={activeStep === 0}
        >
          Indietro
        </Button>
      )}
      {activeStep === formData.pages[0].groups.length - 1 || isSubmitted ? (
        <Button
          type="submit"
          variant="contained"
          endIcon={<SendIcon />}
          disabled={isSubmitting || !isEditing || !isFormValid}
          sx={{ ml: "auto" }}
        >
          {isSubmitting ? (
            <CircularProgress size={24} />
          ) : isSubmitted ? (
            "Salva"
          ) : (
            "Salva"
          )}
        </Button>
      ) : (
        <Button
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          onClick={() =>
            setActiveStep((prevStep) =>
              Math.min(formData.pages[0].groups.length - 1, prevStep + 1)
            )
          }
          sx={{ ml: "auto" }}
        >
          Continua
        </Button>
      )}
    </Box>
  );

  const validateForm = useCallback(() => {
    if (!formData) return false;

    const requiredFields = formData.pages.flatMap((page) =>
      page.groups.flatMap((group) =>
        group.fields.filter(
          (field) => field.required && evaluateFieldConditions(field.conditions)
        )
      )
    );

    const isValid = requiredFields.every((field) => {
      const value = formState[field.field_id];
      return value !== undefined && value !== "" && value.length !== 0;
    });

    setIsFormValid(isValid);
    return isValid;
  }, [formData, formState, evaluateFieldConditions]);

  useEffect(() => {
    validateForm();
  }, [formState, validateForm]);

  useEffect(() => {
    calculateProgress();
  }, [formState, formData]);

  const calculateProgress = () => {
    if (!formData) return;

    const totalFields = formData.pages.reduce(
      (total, page) =>
        total +
        page.groups.reduce(
          (groupTotal, group) =>
            groupTotal +
            group.fields.filter((field) =>
              evaluateFieldConditions(field.conditions)
            ).length,
          0
        ),
      0
    );

    const filledFields = Object.entries(formState).filter(
      ([fieldId, value]) => {
        const field = formData.pages
          .flatMap((page) => page.groups.flatMap((group) => group.fields))
          .find((f) => f.field_id === fieldId);
        return (
          field &&
          evaluateFieldConditions(field.conditions) &&
          value !== "" &&
          value.length !== 0
        );
      }
    ).length;

    const newProgress = (filledFields / totalFields) * 100;
    setProgress(newProgress);
  };

  const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const renderDescription = (description, linkType) => {
    // Add null check for description
    if (!description) {
      return null;
    }
  
    const parts = description.split(/(https?:\/\/\S+)/gi);
    return parts.map((part, index) => {
      if (part.match(/(https?:\/\/\S+)/gi)) {
        const linkStyle = {
          fontWeight: "bold",
          textDecoration: "underline",
          display: "inline-flex",
          alignItems: "center",
          gap: "4px",
          ...(linkType === "gdpr"
            ? { color: theme.palette.info.main }
            : { color: theme.palette.secondary.main }),
        };
  
        const icon =
          linkType === "gdpr" ? (
            <LockIcon fontSize="small" />
          ) : (
            <CampaignIcon fontSize="small" />
          );
  
        return (
          <Link
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            sx={linkStyle}
          >
            {icon}
            {linkType === "gdpr" ? "Link Privacy Policy" : "Link "}
          </Link>
        );
      }
      return part;
    });
  };

  const renderField = (field) => {
    if (!evaluateFieldConditions(field.conditions)) return null;

    const commonProps = {
      key: field.field_id,
      label: field.label,
      fullWidth: true,
      margin: "normal",
      value: formState[field.field_id] || "",
      onChange: (e) =>
        handleChange(field.field_id, e.target.value, field.field_type),
      required: field.required,
      sx: { mb: 2 },
      disabled: !isEditing,
    };

    const renderEditableField = () => {
      switch (field.field_type) {
        case "text":
          return (
            <TextField
              {...commonProps}
              multiline
              rows={1}
              value={formState[field.field_id] || ""}
              onChange={(e) =>
                handleChange(field.field_id, e.target.value, field.field_type)
              }
            />
          );
        case "email":
          return (
            <TextField
              {...commonProps}
              type="email"
              inputProps={{
                pattern: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
              }}
              error={
                formState[field.field_id] &&
                !isValidEmail(formState[field.field_id])
              }
              value={formState[field.field_id] || ""}
              onChange={(e) =>
                handleChange(field.field_id, e.target.value, field.field_type)
              }
            />
          );
        case "number":
          if (field.keyword && field.keyword.toLowerCase().includes("phone")) {
            return (
              <TextField
                {...commonProps}
                type="tel"
                inputProps={{
                  pattern: "[0-9]*",
                  inputMode: "numeric",
                }}
                value={formState[field.field_id] || ""}
                onChange={(e) =>
                  handleChange(field.field_id, e.target.value, field.field_type)
                }
              />
            );
          } else {
            return (
              <TextField
                {...commonProps}
                type="number"
                value={formState[field.field_id] || ""}
                onChange={(e) =>
                  handleChange(field.field_id, e.target.value, field.field_type)
                }
              />
            );
          }
        case "textarea":
          return (
            <TextField
              {...commonProps}
              multiline
              rows={4}
              value={formState[field.field_id] || ""}
              onChange={(e) =>
                handleChange(field.field_id, e.target.value, field.field_type)
              }
            />
          );
        case "select":
          // Add null check for options
          const selectOptions = field.options ? field.options.split(",") : [];
          return (
            <FormControl
              {...commonProps}
              sx={{
                ...commonProps.sx,
                zIndex: 99999,
                position: "relative",
                "& .MuiSelect-select": {
                  zIndex: 99999,
                },
                "& .MuiMenu-paper": {
                  zIndex: 99999,
                },
              }}
            >
              <InputLabel id={`label-${field.field_id}`}>
                {field.label}
              </InputLabel>
              <Select
                labelId={`label-${field.field_id}`}
                id={`select-${field.field_id}`}
                label={field.label}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                      zIndex: 99999,
                    },
                  },
                }}
                {...commonProps}
              >
                {selectOptions.map((option) => (
                  <MenuItem key={option.trim()} value={option.trim()}>
                    {option.trim()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        case "radio":
          // Add null check for options
          const radioOptions = field.options ? field.options.split(",") : [];
          return (
            <FormControl
              component="fieldset"
              fullWidth
              margin="normal"
              required={field.required}
              disabled={!isEditing}
              sx={{ mb: 2 }}
            >
              <Typography component="legend">{field.label}</Typography>
              <RadioGroup
                value={formState[field.field_id] || ""}
                onChange={(e) =>
                  handleChange(field.field_id, e.target.value, field.field_type)
                }
              >
                {radioOptions.map((option) => (
                  <FormControlLabel
                    key={option.trim()}
                    value={option.trim()}
                    control={<Radio />}
                    label={option.trim()}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          );
        case "checkbox":
          const checkedValues = formState[field.field_id] || [];
    
          if (field.keyword === "gdpr" || field.keyword === "marketing") {
            return (
              <Box mt={2}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Chip
                    icon={
                      checkedValues.includes(field.keyword) ? (
                        <CheckCircleIcon />
                      ) : (
                        <CancelIcon />
                      )
                    }
                    label={field.label.toUpperCase()}
                    color={
                      checkedValues.includes(field.keyword)
                        ? "success"
                        : "default"
                    }
                    size="small"
                    onClick={
                      isEditing
                        ? () =>
                            handleChange(
                              field.field_id,
                              field.keyword,
                              "checkbox"
                            )
                        : undefined
                    }
                  />
                </Box>
                <Typography variant="body2" mt={1}>
                  {renderDescription(field.description, field.keyword)}
                </Typography>
              </Box>
            );
          } else {
            // Add null check for options
            const checkboxOptions = field.options ? field.options.split(",") : [];
            return (
              <FormGroup key={field.field_id}>
                <Typography component="legend">{field.label}</Typography>
                {checkboxOptions.map((option) => {
                  const trimmedOption = option.trim();
                  return (
                    <FormControlLabel
                      key={trimmedOption}
                      control={
                        <Checkbox
                          checked={checkedValues.includes(trimmedOption)}
                          onChange={() =>
                            handleChange(
                              field.field_id,
                              trimmedOption,
                              "checkbox"
                            )
                          }
                          disabled={!isEditing}
                        />
                      }
                      label={trimmedOption}
                    />
                  );
                })}
              </FormGroup>
            );
          }
        default:
          return null;
      }
    };
    return (
      <Box key={field.field_id} sx={{ position: "relative" }}>
        {renderEditableField()}
        {field.description &&
          field.keyword !== "gdpr" &&
          field.keyword !== "marketing" && (
            <Tooltip title={field.description} arrow placement="top-start">
              <IconButton
                size="small"
                sx={{ position: "absolute", top: 0, right: -30 }}
              >
                <HelpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
      </Box>
    );
  };

  const renderGroup = (group) => {
    return (
      <motion.div
        key={group.id}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
      >
        <Card
          elevation={3}
          sx={{
            mb: 3,
            borderRadius: 2,
          }}
        >
          <CardContent>
            <Typography variant="h6" gutterBottom color="secondary">
              {group.label}
            </Typography>
            {group.description && (
              <Typography variant="body2" paragraph color="text.secondary">
                {group.description}
              </Typography>
            )}
            {group.fields.map((field) => renderField(field))}
          </CardContent>
        </Card>
      </motion.div>
    );
  };

  const handleCreateSubmit = async () => {
    setIsSubmitting(true);
    setIsLoading(true);
    setError(null);
    try {
      const fieldResponses = formData.pages.flatMap((page) =>
        page.groups.flatMap((group) =>
          group.fields.map((field) => ({
            field: field.id,
            value: formState[field.field_id] || "",
          }))
        )
      );

      const result = await submitFormResponse(formData.id, fieldResponses);
      saveFieldsToLocalStorage();
      setIsSubmitted(true);
      setIsEditing(false);
      setOriginalFormState({ ...formState, id: result.id });
      localStorage.setItem("isSubmitted", JSON.stringify(true));
      localStorage.setItem("formState", JSON.stringify({ ...formState, id: result.id }));
      
      // Send message to chat and wait for it to complete
      await onSendMessage("Dati personali salvati", null, null, formState);
      
      // Use a slightly longer timeout to ensure the message is rendered
      setTimeout(scrollToBottom, 300);

      setSnackbar({
        open: true,
        message: "Form submitted successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Form submission failed:", error);
      setError("Failed to submit form. Please try again later.");
      setSnackbar({
        open: true,
        message: "Failed to submit form. Please try again.",
        severity: "error",
      });
    } finally {
      setIsSubmitting(false);
      setIsLoading(false);
    }
  };

  const handleUpdateSubmit = async () => {
    setIsSubmitting(true);
    setIsLoading(true);
    setError(null);
    try {
      const fieldResponses = formData.pages.flatMap((page) =>
        page.groups.flatMap((group) =>
          group.fields.map((field) => ({
            field: field.id,
            value: formState[field.field_id] || "",
          }))
        )
      );

      const result = await submitFormResponse(formData.id, fieldResponses);
      saveFieldsToLocalStorage();
      setIsEditing(false);
      setOriginalFormState({ ...formState });
      localStorage.setItem("formState", JSON.stringify(formState));
      
      // Send message to chat and wait for it to complete
      await onSendMessage("Dati personali aggiornati", null, null, formState);
      
      // Use a slightly longer timeout to ensure the message is rendered
      setTimeout(scrollToBottom, 300);

      setSnackbar({
        open: true,
        message: "Dati salvati con successo!",
        severity: "success",
      });
    } catch (error) {
      console.error("Form update failed:", error);
      setError("Failed to update form. Please try again later.");
      setSnackbar({
        open: true,
        message: "Failed to update form. Please try again.",
        severity: "error",
      });
    } finally {
      setIsSubmitting(false);
      setIsLoading(false);
    }
  };

  const saveFieldsToLocalStorage = () => {
    const fieldsToSave = [
      { keyword: "is_company" },
      { keyword: "first_name" },
      { keyword: "last_name" },
      { keyword: "name" },
      { keyword: "email" },
      { keyword: "phone" },
      { keyword: "gdpr" },
      { keyword: "marketing" },
    ];

    fieldsToSave.forEach((field) => {
      const fieldId = findFieldIdByKeyword(field.keyword);
      if (fieldId) {
        const value = formState[fieldId];
        if (value !== undefined && value !== null) {
          const storageKey = `aitomotivelab_personalData_${field.keyword}`;
          let storageValue = Array.isArray(value)
            ? value.join(",")
            : typeof value === "object"
            ? JSON.stringify(value)
            : String(value);
          localStorage.setItem(storageKey, storageValue);
        }
      }
    });
  };

  const findFieldIdByKeyword = (keyword) => {
    for (const page of formData.pages) {
      for (const group of page.groups) {
        const field = group.fields.find((f) => f.keyword === keyword);
        if (field) {
          return field.field_id;
        }
      }
    }
    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (isSubmitted) {
        handleUpdateSubmit();
      } else {
        handleCreateSubmit();
      }
    } else {
      setSnackbar({
        open: true,
        message: "Please fill all required fields.",
        severity: "warning",
      });
    }
  };

  const handleEdit = () => {
    if (isEditing) {
      // If currently editing, show a confirmation dialog if there are unsaved changes
      if (hasChanges) {
        if (
          window.confirm(
            "You have unsaved changes. Are you sure you want to cancel?"
          )
        ) {
          setFormState(originalFormState);
          setIsEditing(false);
          setHasChanges(false);
        }
      } else {
        setIsEditing(false);
      }
    } else {
      // If not editing, enter edit mode
      setOriginalFormState({ ...formState });
      setIsEditing(true);
      setHasChanges(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (error) {
    return (
      <Box sx={{ maxWidth: 800, mx: "auto", p: 3 }}>
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!formData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100%",
        mx: "auto",
        p: 2,
        boxSizing: "border-box",
      }}
    >
      {error ? (
        <Typography color="error" align="center">
          {error}
        </Typography>
      ) : !formData ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isSubmitted && (
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
              <IconButton onClick={handleEdit}>
                {isEditing ? <CloseIcon /> : <EditIcon />}
              </IconButton>
            </Box>
          )}

          <AnimatePresence mode="wait">
            <motion.div
              key={activeStep}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.3 }}
            >
              {renderGroup(formData.pages[0].groups[activeStep])}
            </motion.div>
          </AnimatePresence>
          {renderNavigation()}
        </form>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PersonalDataForm;
