import {
  AttachFile as AttachFileIcon,
  Favorite as FavoriteIcon,
  InsertDriveFile as FileIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import { Badge, Box, IconButton, Paper, TextField } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { StyledComponentsThemWrapper } from "./StyledComponentsThemWrapper";
import WishlistPopup from "./WishlistPopup";

const FooterContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.borderColor};
  background-color: ${({ theme }) => theme.palette.footer.background};
  padding: ${({ theme }) => theme.customSpacing.footerPadding};
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 0;
  z-index: 1000;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.customSpacing.footerPaddingMobile};
    padding-bottom: max(${({ theme }) => theme.customSpacing.footerPaddingMobile}, env(safe-area-inset-bottom));
    min-height: 56px;
    position: sticky;
    bottom: 0;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.footer.background};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding-bottom: env(safe-area-inset-bottom, 0);
  }
`;


const ContentWrapper = styled.div`
  padding-bottom: ${({ footerHeight }) => footerHeight}px;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding-bottom: ${({ footerHeight }) => footerHeight + 20}px; // Add extra padding on mobile
  }
`;

const InputContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;

// lore modifiche
const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null, 
  value: string
) => {
  useEffect(() => {
    if (textAreaRef) {
      // Reset height to ensure proper calculation
      textAreaRef.style.height = '0px';
      const scrollHeight = textAreaRef.scrollHeight;
      
      // Set the height based on content
      textAreaRef.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef, value]);
};

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    font-size: ${({ theme }) => theme.typography.inputFontSize};
    border-radius: ${({ theme }) => theme.shape.inputBorderRadius};
    padding: ${({ theme }) => theme.customSpacing.inputPadding};
    background-color: ${({ theme }) => theme.palette.footer.inputBackground};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    transition: all 0.3s ease;
    
    textarea {
      overflow-y: hidden;
      resize: none;
      min-height: 20px;
      max-height: 150px;
      line-height: 1.5;    // ADDED: Better line height
      padding: 8px 12px;   // ADDED: Consistent padding
    }
    
    &.Mui-focused {
      background-color: ${({ theme }) => theme.palette.footer.inputBackgroundFocused};
      box-shadow: 0 0 0 3px ${({ theme }) => theme.palette.footer.inputFocusShadow};
    }
  }
`;

const ActionButton = styled(IconButton)`
  background-color: ${({ theme }) =>
    theme.palette.footer.buttonBackground} !important;
  color: ${({ theme }) => theme.palette.footer.buttonColor} !important;
  width: ${({ theme }) => theme.sizing.buttonSize} !important;
  height: ${({ theme }) => theme.sizing.buttonSize} !important;
  margin-right: ${({ theme }) => theme.customSpacing.buttonMargin} !important;
  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.footer.buttonBackgroundHover}!important;
  }
`;

const ChatFooter = ({
  onSendMessage,
  onSendFile,
  onSendAudio,
  isLoading,
  wishlistItems,
  onWishlistUpdate,
  isHumanControlActive,
}) => {
  const [message, setMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isWishlistOpen, setIsWishlistOpen] = useState(false);
  const fileInputRef = useRef(null);
  const textAreaRef = useRef(null);
  const footerRef = useRef(null);
  const [footerHeight, setFooterHeight] = useState(0);

  // Add effect to measure footer height
  useEffect(() => {
    if (footerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          setFooterHeight(entry.contentRect.height);
        }
      });

      resizeObserver.observe(footerRef.current);
      return () => resizeObserver.disconnect();
    }
  }, []);

  // Auto-resize effect for textarea
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '0px';
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + 'px';
    }
  }, [message]);


  const handleFocus = () => {
    requestAnimationFrame(() => {  // ADDED: Use requestAnimationFrame for smoother handling
      if (textAreaRef.current) {
        const scrollableParent = textAreaRef.current.closest('[style*="overflow: auto"]');
        
        if (scrollableParent) {
          scrollableParent.scrollTo({
            top: scrollableParent.scrollHeight,
            behavior: 'smooth'
          });
          
          // Temporary scroll lock during keyboard appearance
          const lockScroll = () => {
            scrollableParent.scrollTop = scrollableParent.scrollHeight;
          };
          
          scrollableParent.addEventListener('scroll', lockScroll);
          
          setTimeout(() => {
            scrollableParent.removeEventListener('scroll', lockScroll);
          }, 1000);
        }
      }
    });
  };


  const handleBlur = () => {
    if (textAreaRef.current) {
      const scrollableParent = textAreaRef.current.closest('[style*="overflow: auto"]');
      if (scrollableParent) {
        // Optional: scroll to bottom when losing focus
        scrollableParent.scrollTo({
          top: scrollableParent.scrollHeight,
          behavior: 'smooth'
        });
      }
    }
  };


  const handleSend = () => {
    if ((message.trim() || selectedFile) && (!isLoading || isHumanControlActive)) {
      if (selectedFile) {
        onSendFile(selectedFile, message);
        setSelectedFile(null);
      } else {
        onSendMessage(message);
      }
      setMessage("");
      
      // ADDED: Reset textarea height after sending
      if (textAreaRef.current) {
        textAreaRef.current.style.height = 'auto';
      }
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        setSelectedFile(file);
      } else {
        alert(
          "Invalid file type or size. Please select a JPEG, PNG, or PDF file under 5MB."
        );
      }
    }
  };

  const toggleWishlist = () => {
    setIsWishlistOpen(!isWishlistOpen);
  };

  return (
    <StyledComponentsThemWrapper>
      <FooterContainer elevation={3} ref={footerRef}>
        <InputContainer>
          <StyledTextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder={process.env.REACT_APP_CHAT_INPUT_PLACEHOLDER}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            multiline
            rows={1}
            inputRef={textAreaRef}
            sx={{
              flex: 1,
              marginRight: "15px",
              maxHeight: "none",
              position: 'relative',
              zIndex: 1050,
            }}
            disabled={isLoading && !isHumanControlActive}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileSelect}
            disabled={isLoading && !isHumanControlActive}
            accept=".jpg,.jpeg,.png"
          />
          <ActionButton
            component={motion.button}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => fileInputRef.current.click()}
            disabled={isLoading && !isHumanControlActive}
            aria-label="Attach file"
          >
            <Badge
              badgeContent={selectedFile ? <FileIcon fontSize="small" /> : null}
              color="secondary"
            >
              <AttachFileIcon />
            </Badge>
          </ActionButton>
          <AnimatePresence>
            {message.trim() || selectedFile ? (
              <ActionButton
                component={motion.button}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={handleSend}
                disabled={isLoading && !isHumanControlActive}
                aria-label="Send message"
              >
                <SendIcon />
              </ActionButton>
            ) : (
              <ActionButton
                component={motion.button}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={toggleWishlist}
                disabled={isLoading && !isHumanControlActive}
                aria-label="Open Wishlist"
              >
                <Badge
                  badgeContent={
                    wishlistItems.length > 0 ? wishlistItems.length : null
                  }
                  color="secondary"
                >
                  <FavoriteIcon />
                </Badge>
              </ActionButton>
            )}
          </AnimatePresence>
        </InputContainer>
      </FooterContainer>
      <WishlistPopup
        open={isWishlistOpen}
        handleClose={() => setIsWishlistOpen(false)}
        wishlistItems={wishlistItems}
        onWishlistUpdate={onWishlistUpdate}
        onSendMessage={onSendMessage}
      />
    </StyledComponentsThemWrapper>
  );
};

export default ChatFooter;