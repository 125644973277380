import { LinearProgress } from "@mui/material";
import styled, { css, keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const loadingFade = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

export const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`;

export const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 32px;
  width: 100%;
  max-width: ${(props) => (props.isWide ? "90%" : props.theme.maxWidth)};
  font-family: inherit;
  transition: all 0.3s ease;
  box-shadow: 0 0 00px rgba(0, 0, 0, 0.1);

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    border-radius: 20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 16px;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.sender === "user" ? "flex-end" : "flex-start"};
  width: 100%;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation: ${fadeIn} 0.6s ease-out;
`;

export const MessagePaper = styled.div`
  position: relative;
  padding: 16px 24px;
  border-radius: 24px;
  background: ${(props) =>
    props.sender === "user"
      ? props.theme.palette.v2.primary
      : props.theme.palette.v2.secondary};
  color: ${(props) =>
    props.sender === "user"
      ? props.theme.palette.v2.textPrimary
      : props.theme.palette.v2.textSecondary};
  ${(props) => (props.sender === "user" ? `` : `max-width: 75%;`)};
  align-self: ${(props) =>
    props.sender === "user" ? "flex-end" : "flex-start"};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);

  font-family: "Lato", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.2px;

  /* Add word wrapping properties */
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;

  /* Ensure proper overflow handling */
  overflow: hidden;

  /* Style text content */
  & p, & h1, & h2, & h3, & h4, & h5, & h6, & span, & div {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 100%;
  }

  /* Handle pre and code blocks */
  & pre, & code {
    white-space: pre-wrap;
    max-width: 100%;
    overflow-x: auto;
  }

  /* Handle links */
  & a {
    word-break: break-all;
  }

  ${(props) =>
    props.sender === "user"
      ? `
        font-weight: 400;
      `
      : `
        font-weight: 300;
      `}

  &:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background: inherit;
    bottom: -6px;
    ${(props) => (props.sender === "user" ? "right: 20px;" : "left: 20px;")}
    transform: rotate(45deg);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: 100%;
    padding: 14px 18px;
    font-size: 0.95rem;
  }

  ${(props) =>
    props.sender !== "user" &&
    css`
      animation: ${pulseAnimation} 2s infinite;
    `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    gap: 12px;
    margin-top: 20px;
  }
`;

export const LoadingMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  animation: ${loadingFade} 1s infinite;
`;

export const CustomButton = styled.button`
  background: ${(props) => props.theme.palette.buttonBackground};
  color: ${(props) => props.theme.palette.buttonText};
  border: none;
  width: 100%;
  padding: 14px 24px;
  border-radius: 12px;
  cursor: pointer;
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;

  &:hover {
    background-color: ${(props) => props.theme.palette.primaryDark};
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 12px 20px;
    font-size: 14px;
  }
`;

export const MessageContent = styled.div`
  position: relative;
`;

export const FileContainer = styled.div`
  margin-top: 16px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
`;

export const FileImage = styled.img`
  max-width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px;
`;

export const FileAudio = styled.audio`
  margin-top: 8px;
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
`;

export const FileOther = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: ${(props) => props.theme.palette.lightBackground};
  border-radius: 12px;
`;

export const FileIcon = styled.div`
  font-size: 24px;
  margin-right: 12px;
  color: ${(props) => props.theme.palette.primary};
`;

export const FileName = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.palette.text};
`;

export const MessageGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.sender === "user" ? "flex-end" : "flex-start"};
`;

export const ToolMessage = styled.span`
  font-style: italic;
  margin-left: 8px;
  color: ${(props) => props.theme.palette.secondaryText};
`;

export const ToolProgress = styled(LinearProgress)`
  width: 100%;
  margin-top: 8px;
`;
