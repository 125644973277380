import { ChatBubble as ChatIcon } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import { StyledComponentsThemWrapper } from "./StyledComponentsThemWrapper";

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const ToggleContainer = styled(motion.div)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
`;

const SpeechBubble = styled(motion.div)`
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.palette.primary.main},
    ${(props) => props.theme.palette.primary.main}
  );
  color: white;
  padding: 10px 20px;
  border-radius: 18px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  position: relative;
  text-align: center;
  margin-right: -20px;
  animation: ${bounce} 2s infinite;

  &::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 50%;
    width: 16px;
    height: 16px;
    background: linear-gradient(
      135deg,
      ${(props) => props.theme.palette.primary.main},
      ${(props) => props.theme.palette.primary.main}
    );
    clip-path: polygon(100% 0, 0 50%, 100% 100%);
    transform: translateX(-50%) rotate(-0deg);
  }
`;

const StyledIconButton = styled(IconButton)`
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.palette.primary.main},
    ${(props) => props.theme.palette.primary.main}
  ) !important;
  color: white !important;
  width: 56px !important;
  height: 56px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3) !important;
  transition: all 0.3s ease !important;

  &:hover {
    background: linear-gradient(
      135deg,
      ${(props) => props.theme.palette.primary.main}
    ) !important;
    transform: scale(1.1) rotate(10deg) !important;
  }
`;

const ToggleChat = ({ isChatVisible, toggleChat }) => {
  return (
    <StyledComponentsThemWrapper>
      <ToggleContainer>
        <AnimatePresence>
          {!isChatVisible && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
              whileHover={{ scale: 1.05, y: -5 }}
            >
              <SpeechBubble>Come possiamo aiutarti?</SpeechBubble>
            </motion.div>
          )}
        </AnimatePresence>
        <Tooltip title="Chat with us!" placement="left">
          <StyledIconButton
            aria-label="toggle chat"
            onClick={toggleChat}
            size="medium"
          >
            <ChatIcon fontSize="large" />
          </StyledIconButton>
        </Tooltip>
      </ToggleContainer>
    </StyledComponentsThemWrapper>
  );
};

export default ToggleChat;
