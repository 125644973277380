import RefreshIcon from "@mui/icons-material/Refresh";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { Box, Button, CircularProgress, Fade, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import CustomCarousel from "./CustomCarousel";
import PersonalDataForm from "./PersonalData";
import {
  ButtonContainer,
  ChatContainer,
  CustomButton,
  FileAudio,
  FileContainer,
  FileIcon,
  FileImage,
  FileName,
  FileOther,
  LoadingMessage,
  MessageContainer,
  MessageContent,
  MessageGroup,
  MessagePaper,
  MessageWrapper,
  OuterContainer,
  ToolProgress,
} from "./style";

import {getToolDisplay} from "../../utils/toolDisplayMapping";
import { StyledComponentsThemWrapper } from "../StyledComponentsThemWrapper";

const lastMessageGroupId = "last-message-group";
const lastMessageId = "last-message";

const ConnectionStatus = ({
  isConnected,
  isReconnecting,
  reconnectCountdown,
  reconnectAttempts,
  MAX_RECONNECT_ATTEMPTS,
  onManualReconnect,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [dots, setDots] = useState("");

  useEffect(() => {
    if (isReconnecting) {
      setIsVisible(true);
      const dotsInterval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + "." : ""));
      }, 500);
      return () => clearInterval(dotsInterval);
    } else if (!isConnected) {
      setIsVisible(true);
    } else {
      setIsVisible(true);
      const hideTimeout = setTimeout(() => setIsVisible(false), 3000);
      return () => clearTimeout(hideTimeout);
    }
  }, [isConnected, isReconnecting]);

  if (!isVisible || isConnected) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <Box
        sx={{
          p: 2,
          m: 2,
          borderRadius: 2,
          bgcolor: "background.paper",
          boxShadow: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: isConnected
              ? "success.main"
              : isReconnecting
              ? "warning.main"
              : "error.main",
          }}
        >
          {isConnected ? (
            <WifiIcon />
          ) : isReconnecting ? (
            <RefreshIcon className="spin" />
          ) : (
            <WifiOffIcon />
          )}
          <Typography variant="body1">
            {isConnected
              ? "Connected"
              : isReconnecting
              ? "Reconnecting"
              : "Disconnected"}
          </Typography>
        </Box>

        {isReconnecting && (
          <>
            <Typography variant="body2">
              Attempt {reconnectAttempts} of {MAX_RECONNECT_ATTEMPTS}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <CircularProgress size={16} />
              <Typography variant="body2">
                Retrying in {reconnectCountdown}s{dots}
              </Typography>
            </Box>
          </>
        )}

        {!isConnected && !isReconnecting && (
          <Button
            variant="contained"
            size="small"
            startIcon={<RefreshIcon />}
            onClick={onManualReconnect}
          >
            Retry Connection
          </Button>
        )}
      </Box>
    </motion.div>
  );
};

const ChatBody = ({
  messages,
  isLoading,
  locale,
  onSendMessage,
  onWishlistUpdate,
  onSendPersonalData,
  isConnected,
  isAuthenticated,
  isHumanControlled,
  setIsLoading,
  wishlistItems,
  accessToken
}) => {
  const [showButtons, setShowButtons] = useState(true);
  const [mimeTypes, setMimeTypes] = useState({});
  const lastHumanControlledIndex = useRef(-1);
  const observerRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      const lastMessage = messages[messages.length - 1];
      const lastMessageGroupElement =
        document?.getElementById(lastMessageGroupId);
      const lastMessageElement = document?.getElementById(lastMessageId);

      if (
        lastMessage?.tool === "customer_registration" ||
        (lastMessage?.text === "" &&
          lastMessage?.loading &&
          lastMessage?.sender === "server")
      ) {
        lastMessageElement?.scrollIntoView({ behavior: "smooth" });
      } else if (lastMessage?.tool === "booking_tool") {
        lastMessageElement?.scrollIntoView({ behavior: "smooth" });
      } else {
        lastMessageGroupElement?.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 200);
  }, [messages]);

  useEffect(() => {
    messages.forEach(async (message, index) => {
      if (!message.id) {
        message.id = `message-${index}`;
      }
      if (message.file_url) {
        const mimeType = await checkMimeType(getFullFileUrl(message.file_url));
        setMimeTypes((prev) => ({ ...prev, [message.file_url]: mimeType }));
      }
    });
  }, [messages]);

  useEffect(() => {
    const messageElements = document.querySelectorAll("[data-message-id]");
    messageElements.forEach((el) => {
      if (observerRef.current) {
        observerRef.current.observe(el);
      }
    });

    return () => {
      if (observerRef.current) {
        messageElements.forEach((el) => {
          observerRef.current.unobserve(el);
        });
      }
    };
  }, [messages]);

  useEffect(() => {
    if (isHumanControlled) {
      lastHumanControlledIndex.current = messages.length - 1;
    }
  }, [isHumanControlled, messages]);

  const handleCleanChat = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleButtonClick = (message) => {
    setShowButtons(false);
    onSendMessage(message);
  };

  const getFullFileUrl = useCallback((fileUrl) => {
    if (fileUrl.startsWith("blob:")) {
      return fileUrl;
    }
    const domain = process.env.REACT_APP_DOMAIN || "";
    return `${domain}${fileUrl}`;
  }, []);

  const getFileType = useCallback((fileUrl) => {
    let extension;
    if (fileUrl.startsWith("blob:")) {
      extension = "blob";
    } else {
      extension = fileUrl.split(".").pop().toLowerCase();
    }

    if (["jpg", "jpeg", "png", "gif", "webp", "blob"].includes(extension)) {
      return "image";
    } else if (["mp3", "wav", "ogg", "m4a", "aac"].includes(extension)) {
      return "audio";
    } else {
      return "other";
    }
  }, []);

  const checkMimeType = useCallback(async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const contentType = response.headers.get("Content-Type");
      return contentType;
    } catch (error) {
      console.error("Error checking MIME type:", error);
    }
  }, []);

  const renderFile = useCallback(
    (fileUrl, fileName) => {
      console.log("[DEBUG] Access token from props:", !!accessToken);
      
      const fullFileUrl = getFullFileUrl(fileUrl);
      let fileType = getFileType(fileUrl);
      const mimeType = mimeTypes[fileUrl];

      // Use accessToken prop directly, not redeclaring it
      const urlWithToken = accessToken 
      
        ? `${fullFileUrl}?token=${encodeURIComponent(accessToken)}&chat_id=${localStorage.getItem("aitomotivelab_personalData_chat_id")}`
        : fullFileUrl;

      console.log("[DEBUG] File URL constructed:", urlWithToken,mimeType, fileType );

      if (fileType && fileType.includes("audio")) {
        fileType = "audio";
      } else if (fileType && fileType.includes("image")) {
        fileType = "image";
      } else {
        fileType = "other";
      }

      switch (fileType) {
        case "audio":
          return (
            <FileAudio controls>
              <source 
                src={urlWithToken} 
                type={mimeType || "audio/mpeg"} 
                onError={(e) => {
                  console.error("[DEBUG] Audio load error. Token present:", !!accessToken);
                }}
              />
              Your browser does not support the audio element.
            </FileAudio>
          );
        case "image":
          return (
            <FileImage 
              src={urlWithToken} 
              alt={fileName}
              onError={(e) => {
                console.error("[DEBUG] Image load error. Token present:", !!accessToken);
              }}
            />
          );
        default:
          return (
            <FileOther>
              <FileIcon>
                <FaFileAlt />
              </FileIcon>
              <FileName>{fileName}</FileName>
            </FileOther>
          );
      }
    },
    [getFullFileUrl, getFileType, mimeTypes, accessToken] // Add accessToken to dependencies
  );

  const renderMessage = useCallback(
    (message, index) => {
      const isVisible = true; // Temporarily set to true to ensure messages render
      return (
        <Fade in={isVisible} timeout={500}>
          <MessageWrapper
            key={index}
            sender={message.sender}
            data-message-id={message.id}
          >
            <MessageContent>
              {((message.loading && !isHumanControlled) ||
                message.text ||
                message.file_url) && (
                <MessagePaper sender={message.sender}>
                  {message.loading && !isHumanControlled ? (
                    <LoadingMessage>
                      {message.tool && message.tool.length > 0 ? (
                        <>
                          <span>{message.tool.map(tool => getToolDisplay(tool)).join(", ")}</span>
                          {message.status === "tool" ? (
                            <CircularProgress size={28} color="inherit" />
                          ) : (
                            <ToolProgress />
                          )}
                        </>
                      ) : (
                        <>
                          <CircularProgress size={28} color="inherit" />
                        </>
                      )}
                    </LoadingMessage>
                  ) : (
                    <>
                      {(message.text || message.file_url) && (
                        <ReactMarkdown>{message.text}</ReactMarkdown>
                      )}
                      {message.file_url && (
                        <FileContainer>
                          {renderFile(message.file_url, message.file_name)}
                        </FileContainer>
                      )}
                    </>
                  )}
                </MessagePaper>
              )}
            </MessageContent>
            {message.carouselItems && (
              <CustomCarousel
                items={message.carouselItems}
                messageId={message.id}
                whishlist={wishlistItems}
                onWishlistUpdate={onWishlistUpdate}
                style={{ marginTop: "16px" }}
              />
            )}
            {message.tool === "customer_registration" && (
              <PersonalDataForm
                onSubmit={onSendPersonalData}
                onSendMessage={onSendMessage}
                setIsLoading={setIsLoading}
              />
            )}
          </MessageWrapper>
        </Fade>
      );
    },
    [
      isHumanControlled,
      renderFile,
      onWishlistUpdate,
      onSendPersonalData,
      onSendMessage,
    ]
  );

  const renderMessages = useCallback(() => {
    let currentSender = null;
    let messageGroup = [];
    const groupedMessages = [];
    let lastMessageContent = null;

    messages.forEach((message, index) => {
      const isPersonalDataMessage = 
        message.text === "Dati personali aggiornati" ||
        message.text === "Dati personali salvati";

      const shouldRenderMessage =
        message.text?.length > 0 ||
        (message.tool && message.tool?.includes("car_stock_search")) ||
        message.file_url?.length > 0;

      if (message.loading && index <= lastHumanControlledIndex.current) {
        return;
      }

      if (!shouldRenderMessage && !(message.loading && !isHumanControlled)) {
        return;
      }

      // Create a content signature for comparison
      const currentMessageContent = JSON.stringify({
        text: message.text,
        file_url: message.file_url,
        tool: message.tool,
        carouselItems: message.carouselItems
      });

      // Skip if this message is identical to the last one
      if (currentMessageContent === lastMessageContent) {
        return;
      }

      // Update last message content for next comparison
      lastMessageContent = currentMessageContent;

      if (
        (!message.loading || isHumanControlled) &&
        message.sender !== currentSender
      ) {
        if (messageGroup.length > 0) {
          groupedMessages.push(
            <MessageGroup key={`group-${currentSender}-${index}`}>
              <AnimatePresence>
                {messageGroup.map((msg, idx) => (
                  <motion.div
                    key={`${msg.key}-motion`}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3, delay: idx * 0.1 }}
                  >
                    {msg}
                  </motion.div>
                ))}
              </AnimatePresence>
            </MessageGroup>
          );
          messageGroup = [];
        }
        currentSender = message.sender;
      }

      // Wrap the message in a hidden div if it's a personal data message
      const messageElement = isPersonalDataMessage ? (
        <div style={{ display: 'none' }}>
          {renderMessage(message, index)}
        </div>
      ) : renderMessage(message, index);

      messageGroup.push(messageElement);
    });

    if (messageGroup.length > 0) {
      groupedMessages.push(
        <MessageGroup
          key={`group-${currentSender}-last`}
          id={lastMessageGroupId}
        >
          <AnimatePresence>
            {messageGroup.map((msg, idx) => (
              <motion.div
                key={`${msg.key}-motion`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, delay: idx * 0.1 }}
                id={idx === messageGroup.length - 1 ? lastMessageId : undefined}
              >
                {msg}
              </motion.div>
            ))}
          </AnimatePresence>
        </MessageGroup>
      );
    }

    return groupedMessages;
  }, [messages, isHumanControlled, lastHumanControlledIndex, renderMessage]);

  return (
    <StyledComponentsThemWrapper>
      <OuterContainer>
        <ChatContainer>
          <ConnectionStatus
            isConnected={isConnected}
            isReconnecting={false}
            reconnectCountdown={0}
            reconnectAttempts={0}
            MAX_RECONNECT_ATTEMPTS={5}
            onManualReconnect={() => {}}
          />
          {isConnected && (
            <>
              <MessageContainer sender="system">
                <MessagePaper sender="system">
                  <motion.h2
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    style={{ marginBottom: "20px" }}
                  >
                    Benvenuto su {localStorage.getItem("name_chat")}!
                  </motion.h2>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    style={{
                      fontSize: "18px",
                      lineHeight: "1.6",
                      marginBottom: "30px",
                    }}
                  >
                    {localStorage.getItem("welcome_message")}
                  </motion.p>

                  {showButtons ? (
                    <ButtonContainer>
                      {["message_1", "message_2", "message_3"].map(
                        (msg, index) => (
                          <motion.div
                            key={msg}
                            initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{
                              duration: 0.5,
                              delay: 0.6 + index * 0.2,
                            }}
                          >
                            <CustomButton
                              onClick={() =>
                                handleButtonClick(localStorage.getItem(msg))
                              }
                            >
                              {localStorage.getItem(msg)}
                            </CustomButton>
                          </motion.div>
                        )
                      )}
                    </ButtonContainer>
                  ) : (
                    <motion.div
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.5 }}
                    >
                     
                    </motion.div>
                  )}
                </MessagePaper>
              </MessageContainer>
              {renderMessages()}
            </>
          )}
        </ChatContainer>
      </OuterContainer>
    </StyledComponentsThemWrapper>
  );
};

export default ChatBody;
